a[target='_blank']
/* body, html {
  background-color: #003057 !important;
} */
/*
  Hides Sitecore Experience Editor markup,
  if you run the app in connected mode while the Sitecore cookies
  are set to edit mode.
*/
.scChromeData, .scpm {
  display: none !important;
}

@font-face {
  font-family: GTSuperDisplay;
  src: local('GTSuperDisplay'),
    url('./fonts/Latin/GTSuper/GT-Super-Display-Medium.ttf') format('truetype');
    font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: local('Gilroy'),
    url('./fonts/Latin/Gilroy/Radomir\ Tinkov\ -\ Gilroy-Regular.otf')
      format('truetype');
      font-display: swap;
}

@font-face {
  font-family: GTSuperDisplayReg;
  src: local('GTSuperDisplayReg'),
    url('./fonts/Latin/GTSuper/GT-Super-Display-Regular.ttf') format('truetype');
    font-display: swap;
}

@font-face {
  font-family: GTSuperDisplayLight;
  src: local('GTSuperDisplayLight'),
    url('./fonts/Latin/GTSuper/GT-Super-Display-Light.ttf') format('truetype');
    font-display: swap;
}

@font-face {
  font-family: GTSuperDisplayBold;
  src: local('GTSuperDisplayBold'),
    url('./fonts/Latin/GTSuper/GT-Super-Display-Bold.ttf') format('truetype');
    font-display: swap;
}

@font-face {
  font-family: Gilroy-Semibold;
  src: local('Gilroy-Semibold'),
    url('./fonts/Latin/Gilroy/Radomir\ Tinkov\ -\ Gilroy-Light.otf')
      format('truetype');
      font-display: swap;
}

@font-face {
  font-family: Gilroy-Regular;
  src: local('Gilroy-Semibold'),
    url('./fonts/Latin/Gilroy/Radomir\ Tinkov\ -\ Gilroy-Bold.otf')
      format('truetype');
      font-display: swap;
}

@font-face {
  font-family: FSMeridian-Thin;
  src: local('FSMeridian-Thin'),
    url('./fonts/Latin/Meridian/Fontsmith-FSMeridianThin.otf')
      format('truetype');
      font-display: swap;
}
@font-face {
  font-family: FSMeridian-Regular;
  src: local('FSMeridian-Regular'),
    url('./fonts/Latin/Meridian/Fontsmith-FSMeridianRegular.otf')
      format('truetype');
      font-display: swap;
}
@font-face {
  font-family: FSMeridian-Medium;
  src: local('FSMeridian-Medium'),
    url('./fonts/Latin/Meridian/Fontsmith-FSMeridian-Medium.otf')
      format('truetype');
      font-display: swap;
}
@font-face {
  font-family: FSMeridian;
  src: local('FSMeridian'),
    url('./fonts/Latin/Meridian/Fontsmith-FsMeridianVar.ttf') format('truetype');
    font-display: swap;
}

@font-face {
  font-family: NeueFrutigerArabic-Medium;
  src: local('NeueFrutigerArabic-Medium'),
    url('./fonts/Latin/NeueFrutigerArabic/NeueFrutigerArabic-Medium.otf')
      format('truetype');
      font-display: swap;
}

@font-face {
  font-family: NeueFrutigerArabic-Light;
  src: local('NeueFrutigerArabic-Light'),
    url('./fonts/Latin/NeueFrutigerArabic/NeueFrutigerArabic-Light.otf')
      format('truetype');
      font-display: swap;
}

@font-face {
  font-family: NeueFrutigerArabic-Bold;
  src: local('NeueFrutigerArabic-Bold'),
    url('./fonts/Latin/NeueFrutigerArabic/NeueFrutigerArabic-Bold.otf')
      format('truetype');
      font-display: swap;
}

@font-face {
  font-family: SometimesTimes;
  src: local('SometimesTimes'),
    url('./fonts/Latin/Sometimes-Times/SometimesTimes.ttf') format('truetype');
    font-display: swap;
}

@font-face {
  font-family: SometimesTimes;
  src: local('SometimesTimes'),
    url('./fonts/Latin/Sometimes-Times-Web/Sometimes\ Times.woff')
      format('woff');
      font-display: swap;
}

@font-face {
  font-family: SometimesTimes;
  src: local('SometimesTimes'),
    url('./fonts/Latin/Sometimes-Times-Web/Sometimes\ Times.woff2')
      format('woff');
      font-display: swap;
}

@font-face {
  font-family: SometimesTimes;
  src: local('SometimesTimes'), url('./fonts/Latin/Sometimes\ Times.eot');
  font-display: swap;
}

a:hover {
  text-decoration: none;
}
