@import "../../../assets/app.scss";
.error-500-container {
    
    margin: 20px;
    background-image: url("../../../assets/errorpage@3x.png");
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-x: hidden;
    .error-500-message {
        text-align: center;
  font-size: 9px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin: 100px 10px 50px 10px;
        @include desktopsmall {
            height: 40vh;
            margin: 150px 120px 50px;
        }
        h1 {
            width: 90%;
            font-family: SometimesTimes;
            font-size: 65px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.92;
            letter-spacing: normal;
            text-align: center;
            margin: 0 auto;
            text-transform: uppercase;
            @include desktop {
              width: 70%;
            }
        
            @include tablet {
                font-size: 65px;
            }
            @include mobile {
                font-size: 42px;
            }
          }
        p {
            width: 80%;
            margin: 0 auto;
            text-align: center;
            margin-top: 15px;
           font-family: FSMeridian;
           font-size: 21px;
           font-weight: normal;
           font-stretch: normal;
           font-style: normal;
           line-height: 1.29;
           letter-spacing: normal;
            @include desktop {
              margin-top: 22px;
              width: 70%;
            }
            @include tablet {
                font-size: 23px;
            }
          }
          // .error-link {
          //   background-color: #fff;
          //   margin: 25px auto;
          //   display: block;
          //   padding: 8px 0;
          //   width: 80%;
          //   border: 1px solid #fff;
          //   color: #000000;
          //   text-align: center;
          //   text-transform: uppercase;
          //   background-color: rgba($color: #000000, $alpha: 0.2);
          //   font-family: "Gilroy";
          //   @include desktop {
          //     width: 22%;
          //   }
        // }
        .error-link{
        a {
          margin: 25px auto;
          display: block;
          padding: 8px 0;
          width: 80%;
          border: 1px solid #fff;
          color: #000;
          text-align: center;
          text-transform: uppercase;
          background-color: #fff;
          font-family: FSMeridian;
          font-size:17px;
          @include desktop {
            width: 22%;
          }
        }
      }
    }
}