@import "../../../assets/app.scss";
.png-logo {
    width: 90%;
    height: auto;

    @include mobile {
        width: 50%;
    }

    @include tablet {
        width: 50%;
    }
}
.maintain-container {
    margin: 20px;
    .maintenance-image {
        width: 150px;
        @include desktopsmall {
            width: 175px;
        }
    }
.maintenance-message {
  text-align: center;
  font-family: Gilroy;
  font-size: 9px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
        margin: 100px 10px 50px 10px;
        @include desktopsmall {
            height: 40vh;
            margin: 150px 120px 50px;
        }
        h1 {
            font-size: 24px;
            margin-bottom: 45px;
            line-height: 1.4;
            color:black;
            @include desktopsmall {
                font-size: 28px;
            }
        }
    }
}