a[target='_blank']
/* body, html {
  background-color: #003057 !important;
} */
/*
  Hides Sitecore Experience Editor markup,
  if you run the app in connected mode while the Sitecore cookies
  are set to edit mode.
*/
.scChromeData, .scpm {
  display: none !important;
}

@font-face {
  font-family: GTSuperDisplay;
  src: local('GTSuperDisplay'),
    url(/static/media/GT-Super-Display-Medium.635d8788.ttf) format('truetype');
    font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: local('Gilroy'),
    url("/static/media/Radomir Tinkov - Gilroy-Regular.1e09f38c.otf")
      format('truetype');
      font-display: swap;
}

@font-face {
  font-family: GTSuperDisplayReg;
  src: local('GTSuperDisplayReg'),
    url(/static/media/GT-Super-Display-Regular.f3bd4162.ttf) format('truetype');
    font-display: swap;
}

@font-face {
  font-family: GTSuperDisplayLight;
  src: local('GTSuperDisplayLight'),
    url(/static/media/GT-Super-Display-Light.f7551cb0.ttf) format('truetype');
    font-display: swap;
}

@font-face {
  font-family: GTSuperDisplayBold;
  src: local('GTSuperDisplayBold'),
    url(/static/media/GT-Super-Display-Bold.f66d18ee.ttf) format('truetype');
    font-display: swap;
}

@font-face {
  font-family: Gilroy-Semibold;
  src: local('Gilroy-Semibold'),
    url("/static/media/Radomir Tinkov - Gilroy-Light.d90fa984.otf")
      format('truetype');
      font-display: swap;
}

@font-face {
  font-family: Gilroy-Regular;
  src: local('Gilroy-Semibold'),
    url("/static/media/Radomir Tinkov - Gilroy-Bold.17cb9303.otf")
      format('truetype');
      font-display: swap;
}

@font-face {
  font-family: FSMeridian-Thin;
  src: local('FSMeridian-Thin'),
    url(/static/media/Fontsmith-FSMeridianThin.55c61626.otf)
      format('truetype');
      font-display: swap;
}
@font-face {
  font-family: FSMeridian-Regular;
  src: local('FSMeridian-Regular'),
    url(/static/media/Fontsmith-FSMeridianRegular.32d187c4.otf)
      format('truetype');
      font-display: swap;
}
@font-face {
  font-family: FSMeridian-Medium;
  src: local('FSMeridian-Medium'),
    url(/static/media/Fontsmith-FSMeridian-Medium.e6bd2d16.otf)
      format('truetype');
      font-display: swap;
}
@font-face {
  font-family: FSMeridian;
  src: local('FSMeridian'),
    url(/static/media/Fontsmith-FsMeridianVar.6ea975a1.ttf) format('truetype');
    font-display: swap;
}

@font-face {
  font-family: NeueFrutigerArabic-Medium;
  src: local('NeueFrutigerArabic-Medium'),
    url(/static/media/NeueFrutigerArabic-Medium.00c5ac48.otf)
      format('truetype');
      font-display: swap;
}

@font-face {
  font-family: NeueFrutigerArabic-Light;
  src: local('NeueFrutigerArabic-Light'),
    url(/static/media/NeueFrutigerArabic-Light.3752d575.otf)
      format('truetype');
      font-display: swap;
}

@font-face {
  font-family: NeueFrutigerArabic-Bold;
  src: local('NeueFrutigerArabic-Bold'),
    url(/static/media/NeueFrutigerArabic-Bold.69088475.otf)
      format('truetype');
      font-display: swap;
}

@font-face {
  font-family: SometimesTimes;
  src: local('SometimesTimes'),
    url(/static/media/SometimesTimes.f97de5f2.ttf) format('truetype');
    font-display: swap;
}

@font-face {
  font-family: SometimesTimes;
  src: local('SometimesTimes'),
    url("/static/media/Sometimes Times.efaf10ba.woff")
      format('woff');
      font-display: swap;
}

@font-face {
  font-family: SometimesTimes;
  src: local('SometimesTimes'),
    url("/static/media/Sometimes Times.2328b063.woff2")
      format('woff');
      font-display: swap;
}

@font-face {
  font-family: SometimesTimes;
  src: local('SometimesTimes'), url("/static/media/Sometimes Times.fb74aba1.eot");
  font-display: swap;
}

a:hover {
  text-decoration: none;
}

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.error-500-container {
  margin: 20px;
  background-image: url(/static/media/errorpage@3x.3b614075.png);
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden; }
  .error-500-container .error-500-message {
    text-align: center;
    font-size: 9px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin: 100px 10px 50px 10px; }
    @media (min-width: 992px) {
      .error-500-container .error-500-message {
        height: 40vh;
        margin: 150px 120px 50px; } }
    .error-500-container .error-500-message h1 {
      width: 90%;
      font-family: SometimesTimes;
      font-size: 65px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.92;
      letter-spacing: normal;
      text-align: center;
      margin: 0 auto;
      text-transform: uppercase; }
      @media (min-width: 1200px) {
        .error-500-container .error-500-message h1 {
          width: 70%; } }
      @media (min-width: 768px) and (max-width: 991px) {
        .error-500-container .error-500-message h1 {
          font-size: 65px; } }
      @media (max-width: 767px) {
        .error-500-container .error-500-message h1 {
          font-size: 42px; } }
    .error-500-container .error-500-message p {
      width: 80%;
      margin: 0 auto;
      text-align: center;
      margin-top: 15px;
      font-family: FSMeridian;
      font-size: 21px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal; }
      @media (min-width: 1200px) {
        .error-500-container .error-500-message p {
          margin-top: 22px;
          width: 70%; } }
      @media (min-width: 768px) and (max-width: 991px) {
        .error-500-container .error-500-message p {
          font-size: 23px; } }
    .error-500-container .error-500-message .error-link a {
      margin: 25px auto;
      display: block;
      padding: 8px 0;
      width: 80%;
      border: 1px solid #fff;
      color: #000;
      text-align: center;
      text-transform: uppercase;
      background-color: #fff;
      font-family: FSMeridian;
      font-size: 17px; }
      @media (min-width: 1200px) {
        .error-500-container .error-500-message .error-link a {
          width: 22%; } }

/* :: screen widths */
/* :: color variables */
/*mixins declared below*/
/* :: screen widths */
/* :: color variables */
.png-logo {
  width: 90%;
  height: auto; }
  @media (max-width: 767px) {
    .png-logo {
      width: 50%; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .png-logo {
      width: 50%; } }

.maintain-container {
  margin: 20px; }
  .maintain-container .maintenance-image {
    width: 150px; }
    @media (min-width: 992px) {
      .maintain-container .maintenance-image {
        width: 175px; } }
  .maintain-container .maintenance-message {
    text-align: center;
    font-family: Gilroy;
    font-size: 9px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin: 100px 10px 50px 10px; }
    @media (min-width: 992px) {
      .maintain-container .maintenance-message {
        height: 40vh;
        margin: 150px 120px 50px; } }
    .maintain-container .maintenance-message h1 {
      font-size: 24px;
      margin-bottom: 45px;
      line-height: 1.4;
      color: black; }
      @media (min-width: 992px) {
        .maintain-container .maintenance-message h1 {
          font-size: 28px; } }

